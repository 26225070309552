export const CURRENT = "current";
export const FUTURE = "future";

export const LOCATIONS = [
  {
    city: "Warszawa",
    link: "warszawa/dermatoskopowe-badanie-znamion-wideodermatoskopia",
    position: {
      left: 70,
      top: 48,
    },
    price: "400",
    type: CURRENT,
    giftCardLink: "https://s.przelewy24.pl/Gq1gBAJ2FGV2",
  },
  {
    city: "Poznań",
    price: "400",
    link: "poznan/dermatoskopowe-badanie-znamion-wideodermatoskopia",
    position: {
      left: 29,
      top: 45,
    },
    type: CURRENT,
    giftCardLink: "https://s.przelewy24.pl/Gq1gBAJ2FGV2",
  },
  {
    city: "Wrocław",
    link: "wroclaw/dermatoskopowe-badanie-znamion-wideodermatoskopia",
    position: {
      left: 30,
      top: 65,
    },
    type: CURRENT,
    price: "400",
    giftCardLink: "https://s.przelewy24.pl/Gq1gBAJ2FGV2",
  },
  {
    city: "Kraków",
    link: "krakow/dermatoskopowe-badanie-znamion-wideodermatoskopia",
    position: {
      left: 57,
      top: 89,
    },
    type: CURRENT,
    price: "400",
    giftCardLink: "https://s.przelewy24.pl/Gq1gBAJ2FGV2",
  },
  {
    city: "Szczecin",
    link: "szczecin/dermatoskopowe-badanie-znamion-wideodermatoskopia",
    position: {
      left: 7,
      top: 30,
    },
    type: CURRENT,
    price: "400",
    giftCardLink: "https://s.przelewy24.pl/Gq1gBAJ2FGV2",
  },
  {
    city: "Gdynia",
    link: "gdynia/dermatoskopowe-badanie-znamion-wideodermatoskopia",
    position: {
      left: 43,
      top: 9,
    },
    giftCardLink: "https://s.przelewy24.pl/MLTMqdBhGzHC",
    type: CURRENT,
  },
    // {
  //   city: "Gdańsk",
  //   link: "gdansk/dermatoskopowe-badanie-znamion-wideodermatoskopia",
  //   position: {
  //     left: 43,
  //     top: 11,
  //   },
  //   type: CURRENT,
  // },
  // {
  //   city: "Łódź",
  //   price: "400",
  //   link: "lodz/dermatoskopowe-badanie-znamion-wideodermatoskopia",
  //   position: {
  //     left: 53,
  //     top: 57,
  //   },
  //   type: CURRENT,
  //   giftCardLink: false,
  // },

  {
    city: "Katowice",
    link: "katowice/dermatoskopowe-badanie-znamion-wideodermatoskopia",
    position: {
      left: 48,
      top: 82,
    },
    giftCardLink: "https://s.przelewy24.pl/MLTMqdBhGzHC",
    type: CURRENT,
  },

  // {
  //   city: "Bydgoszcz",
  //   link: "bydgoszcz/dermatoskopowe-badanie-znamion-wideodermatoskopia",
  //   position: {
  //     left: 37,
  //     top: 35,
  //   },
  //   type: FUTURE,
  // },
 
  // {
  //   city: "Lublin",
  //   link: undefined,
  //   position: {
  //     left: 82,
  //     top: 63,
  //   },
  //   type: FUTURE,
  // },
  {
    city: "Żywiec",
    link: "zywiec/dermatoskopowe-badanie-znamion-wideodermatoskopia",
    position: {
      left: 49,
      top: 91,
    },
    type: CURRENT,
  },
  // {
  //   city: "Białystok",
  //   link: undefined,
  //   position: {
  //     left: 89,
  //     top: 35,
  //   },
  //   type: FUTURE,
  // },
];